<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0235 2.03358L15.0689 1.77924C12.369 1.05986 11.019 0.700178 9.95548 1.31074C8.89196 1.9213 8.53023 3.26367 7.80678 5.94841L6.78366 9.74521C6.0602 12.4299 5.69848 13.7723 6.3125 14.8298C6.92652 15.8874 8.27651 16.247 10.9765 16.9664L11.9311 17.2208C14.631 17.9401 15.981 18.2998 17.0445 17.6893C18.108 17.0787 18.4698 15.7363 19.1932 13.0516L20.2163 9.25479C20.9398 6.57005 21.3015 5.22768 20.6875 4.17016C20.0735 3.11264 18.7235 2.75295 16.0235 2.03358Z"
      :stroke="iconStroke"
      stroke-width="1.5"
    />
    <path
      d="M15.8538 6.43306C15.8538 7.24714 15.1901 7.90709 14.3714 7.90709C13.5527 7.90709 12.889 7.24714 12.889 6.43306C12.889 5.61898 13.5527 4.95904 14.3714 4.95904C15.1901 4.95904 15.8538 5.61898 15.8538 6.43306Z"
      :stroke="iconStroke"
      stroke-width="1.5"
    />
    <path
      d="M11 19.9463L10.0477 20.2056C7.35403 20.9391 6.00722 21.3059 4.94619 20.6833C3.88517 20.0608 3.52429 18.6921 2.80253 15.9547L1.78182 12.0834C1.06006 9.34601 0.699185 7.97731 1.31177 6.89904C1.84167 5.96631 3 6.00027 4.5 6.00015"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '22',
    },
    width: {
      type: String,
      default: '22',
    },
    color: {
      type: String,
      default: 'primary-purple-600',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStroke() {
      return `var(--${this.color})`
    },
  },
}
</script>
